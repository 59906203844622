export const ATCODES = [
  {
    "Code": "af",
    "Name": "Afghanistan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "dz",
    "Name": "Algeria",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "ar",
    "Name": "Argentina",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "au",
    "Name": "Australia",
    "Languages": [
      {
        "Code": "au",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "at",
    "Name": "Austria",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "de",
        "Name": "German",
      }
    ]
  },
  {
    "Code": "by",
    "Name": "Belarus",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "be",
    "Name": "Belgium",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      },
      {
        "Code": "nl",
        "Name": "Dutch",
      }
    ]
  },
  {
    "Code": "ba",
    "Name": "Bosnia And Herzegovina",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hr",
        "Name": "Croatian",
      }
    ]
  },
  {
    "Code": "br",
    "Name": "Brazil",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "br",
        "Name": "Brazilian",
      }
    ]
  },
  {
    "Code": "bg",
    "Name": "Bulgaria",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "cm",
    "Name": "Cameroon",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "ca",
    "Name": "Canada",
    "Languages": [
      {
        "Code": "ca",
        "Name": "English",
      },
      {
        "Code": "qu",
        "Name": "Quechua",
      }
    ]
  },
  {
    "Code": "cl",
    "Name": "Chile",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "cn",
    "Name": "China",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "cn",
        "Name": "Chinese",
      }
    ]
  },
  {
    "Code": "co",
    "Name": "Colombia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "cd",
    "Name": "Congo",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "hr",
    "Name": "Croatia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hr",
        "Name": "Croatian",
      }
    ]
  },
  {
    "Code": "cz",
    "Name": "Czech Republic",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hr",
        "Name": "Croatian",
      }
    ]
  },
  {
    "Code": "ci",
    "Name": "Côte D'Ivoire",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "dk",
    "Name": "Denmark",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "da",
        "Name": "Danish",
      }
    ]
  },
  {
    "Code": "ec",
    "Name": "Ecuador",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "eg",
    "Name": "Egypt",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "ee",
    "Name": "Estonia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "fi",
    "Name": "Finland",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fi",
        "Name": "Finnish",
      }
    ]
  },
  {
    "Code": "fr",
    "Name": "France",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "ga",
    "Name": "Gabon",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "ge",
    "Name": "Georgia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "de",
    "Name": "Germany",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "de",
        "Name": "German",
      }
    ]
  },
  {
    "Code": "gr",
    "Name": "Greece",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "el",
        "Name": "Greek",
      }
    ]
  },
  {
    "Code": "hk",
    "Name": "Hong Kong",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "zh",
        "Name": "Chinese",
      }
    ]
  },
  {
    "Code": "hu",
    "Name": "Hungary",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hu",
        "Name": "Hungarian",
      }
    ]
  },
  {
    "Code": "is",
    "Name": "Iceland",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "in",
    "Name": "India",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hi",
        "Name": "Hindi",
      }
    ]
  },
  {
    "Code": "id",
    "Name": "Indonesia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "id",
        "Name": "Indonesian",
      }
    ]
  },
  {
    "Code": "iq",
    "Name": "Iraq",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "ie",
    "Name": "Ireland",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "il",
    "Name": "Israel",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "he",
        "Name": "Hebrew",
      }
    ]
  },
  {
    "Code": "it",
    "Name": "Italy",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "it",
        "Name": "Italian",
      }
    ]
  },
  {
    "Code": "jp",
    "Name": "Japan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ja",
        "Name": "Japanese",
      }
    ]
  },
  {
    "Code": "jo",
    "Name": "Jordan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "kz",
    "Name": "Kazakhstan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "kr",
    "Name": "Korea, Republic Of",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ko",
        "Name": "Korean",
      }
    ]
  },
  {
    "Code": "xk",
    "Name": "Kosovo",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "kw",
    "Name": "Kuwait",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "lv",
    "Name": "Latvia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "lb",
    "Name": "Lebanon",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "ly",
    "Name": "Libya",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "lt",
    "Name": "Lithuania",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "lu",
    "Name": "Luxembourg",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      },
      {
        "Code": "de",
        "Name": "German",
      }
    ]
  },
  {
    "Code": "mo",
    "Name": "Macao",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "zh",
        "Name": "Chinese",
      }
    ]
  },
  {
    "Code": "my",
    "Name": "Malaysia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ms",
        "Name": "Malay",
      }
    ]
  },
  {
    "Code": "mv",
    "Name": "Maldives",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "mx",
    "Name": "Mexico",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "me",
    "Name": "Montenegro",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hr",
        "Name": "Croatian",
      }
    ]
  },
  {
    "Code": "ma",
    "Name": "Morocco",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "mm",
    "Name": "Myanmar",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "nr",
    "Name": "Nauru",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "nl",
    "Name": "Netherlands",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "nl",
        "Name": "Dutch",
      }
    ]
  },
  {
    "Code": "nz",
    "Name": "New Zealand",
    "Languages": [
      {
        "Code": "au",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "ng",
    "Name": "Nigeria",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "no",
    "Name": "Norway",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "no",
        "Name": "Norwegian",
      }
    ]
  },
  {
    "Code": "om",
    "Name": "Oman",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "pk",
    "Name": "Pakistan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "pe",
    "Name": "Peru",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "ph",
    "Name": "Philippines",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "pl",
    "Name": "Poland",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "pl",
        "Name": "Polish",
      }
    ]
  },
  {
    "Code": "pt",
    "Name": "Portugal",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "pt",
        "Name": "Portugese",
      }
    ]
  },
  {
    "Code": "qa",
    "Name": "Qatar",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "ro",
    "Name": "Romania",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ro",
        "Name": "Romanian",
      }
    ]
  },
  {
    "Code": "ru",
    "Name": "Russian Federation",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ru",
        "Name": "Russian",
      }
    ]
  },
  {
    "Code": "rw",
    "Name": "Rwanda",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "sa",
    "Name": "Saudi Arabia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "rs",
    "Name": "Serbia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "hr",
        "Name": "Croatian",
      }
    ]
  },
  {
    "Code": "sg",
    "Name": "Singapore",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "cn",
        "Name": "Chinese",
      }
    ]
  },
  {
    "Code": "sk",
    "Name": "Slovakia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sk",
        "Name": "Slovak",
      }
    ]
  },
  {
    "Code": "si",
    "Name": "Slovenia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "za",
    "Name": "South Africa",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "es",
    "Name": "Spain",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "es",
        "Name": "Espanol",
      },
      {
        "Code": "ct",
        "Name": "Catalan",
      }
    ]
  },
  {
    "Code": "se",
    "Name": "Sweden",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sv",
        "Name": "Swedish",
      }
    ]
  },
  {
    "Code": "ch",
    "Name": "Switzerland",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "de",
        "Name": "German",
      },
      {
        "Code": "fr",
        "Name": "French",
      },
      {
        "Code": "it",
        "Name": "Italian",
      }
    ]
  },
  {
    "Code": "tw",
    "Name": "Taiwan",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "zh",
        "Name": "Chinese",
      }
    ]
  },
  {
    "Code": "th",
    "Name": "Thailand",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "th",
        "Name": "Thai",
      }
    ]
  },
  {
    "Code": "to",
    "Name": "Tonga",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "tn",
    "Name": "Tunisia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "tr",
    "Name": "Turkey",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "tr",
        "Name": "Turkish",
      }
    ]
  },
  {
    "Code": "ua",
    "Name": "Ukraine",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "uk",
        "Name": "Ukranian",
      }
    ]
  },
  {
    "Code": "ae",
    "Name": "United Arab Emirates",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "ar",
        "Name": "Arabic",
      }
    ]
  },
  {
    "Code": "uk",
    "Name": "United Kingdom",
    "Languages": [
      {
        "Code": "gb",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "us",
    "Name": "United States",
    "Languages": [
      {
        "Code": "us",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "uy",
    "Name": "Uruguay",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "sp",
        "Name": "Spanish",
      }
    ]
  },
  {
    "Code": "vu",
    "Name": "Vanuatu",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "ve",
    "Name": "Venezuela",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      },
      {
        "Code": "fr",
        "Name": "French",
      }
    ]
  },
  {
    "Code": "vn",
    "Name": "Vietnam",
    "Languages": [
      {
        "Code": "us",
        "Name": "English",
      },
      {
        "Code": "vi",
        "Name": "Vietnamese",
      }
    ]
  },
  {
    "Code": "zm",
    "Name": "Zambia",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
  {
    "Code": "zw",
    "Name": "Zimbabwe",
    "Languages": [
      {
        "Code": "en",
        "Name": "English",
      }
    ]
  },
]
