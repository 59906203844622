var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "v-fixed-right",
              attrs: {
                app: "",
                fixed: "",
                temporary: "",
                right: "",
                width: "450"
              },
              model: {
                value: _vm.showAddedKeyList,
                callback: function($$v) {
                  _vm.showAddedKeyList = $$v
                },
                expression: "showAddedKeyList"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "", height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-0"
                    },
                    [
                      _c("h5", { staticClass: "text-h6" }, [
                        _vm._v(_vm._s(_vm.$t("kai.addedKeywords")))
                      ]),
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              return _vm.closeButton()
                            }
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pa-2 pt-0" }, [
                    _c(
                      "div",
                      { staticClass: "keword-lists pa-3 pt-0" },
                      _vm._l(_vm.addedKeywords, function(keyword) {
                        return _c(
                          "v-row",
                          { key: keyword.text, staticClass: "mt-4" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 pt-3 pl-0",
                                attrs: { cols: "12", sm: "4" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      width: "32",
                                      depressed: "",
                                      "x-small": "",
                                      fab: "",
                                      text: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteKeyword(keyword.text)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-delete-outline")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(keyword.text) + " ")
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 pt-3 word-nowrap",
                                attrs: { cols: "12", sm: "4" }
                              },
                              [
                                _c("v-select", {
                                  staticClass: "no-field-details",
                                  attrs: {
                                    items: _vm.matchTypes,
                                    label: "Match Type",
                                    required: "",
                                    outlined: "",
                                    dense: ""
                                  },
                                  model: {
                                    value: keyword.matchType,
                                    callback: function($$v) {
                                      _vm.$set(keyword, "matchType", $$v)
                                    },
                                    expression: "keyword.matchType"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 pt-3",
                                attrs: { cols: "12", sm: "4" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "no-field-details",
                                      attrs: {
                                        type: "text",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        "prepend-inner-icon": "mdi-currency-usd"
                                      },
                                      model: {
                                        value: keyword.bidAmount.amount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            keyword.bidAmount,
                                            "amount",
                                            $$v
                                          )
                                        },
                                        expression: "keyword.bidAmount.amount"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("v-divider", {
                              staticClass: "ml-1 mr-1 mt-2",
                              attrs: { horizontal: "" }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-space-between pa-3" },
                    [
                      _c("div", { staticClass: "pagination pa-2" }, [
                        _c(
                          "span",
                          { staticClass: "pagination-total secondary--text" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("kai.total")) +
                                " " +
                                _vm._s(_vm.addedKeywords.length)
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "127",
                            color: "grey",
                            depressed: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeButton()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("kai.cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "127",
                            color: "success",
                            depressed: "",
                            disabled: _vm.addedKeywords.length == 0
                          },
                          on: {
                            click: function($event) {
                              return _vm.runAds()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("kai.runAds")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("SelectAdGroup", {
            attrs: { settings: _vm.selectAdgroupForKeywords },
            on: {
              onAdgroupSelection: function($event) {
                return _vm.adgroupsSelectedForKeyword($event)
              },
              keysFromKai: function($event) {
                return _vm.adgroupsKeysFromKai($event)
              }
            }
          }),
          _c("AddKeysKai", {
            attrs: { adgroupDetails: _vm.adgroupMetadata },
            on: {
              onClickChange: function($event) {
                return _vm.openSelectAdgroupForKeywords($event)
              },
              onSaveKeywords: function($event) {
                return _vm.addkeywordsToAdgroups($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "inner-container" },
            [
              _c(
                "div",
                { staticClass: "top-page-header" },
                [
                  _c("div", { staticClass: "head-title" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.$t("pages.kai")))]),
                    _c(
                      "div",
                      { staticClass: "right-head-col d-flex" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "grey--text datepicker-outlined mr-3",
                            attrs: { depressed: "", outlined: "", height: "40" }
                          },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "menu5",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value": _vm.enddate,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "auto"
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    _vm.enddate = $event
                                  },
                                  "update:return-value": function($event) {
                                    _vm.enddate = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "append-icon": "mdi-calendar",
                                                  readonly: ""
                                                },
                                                model: {
                                                  value: _vm.endDate,
                                                  callback: function($$v) {
                                                    _vm.endDate = $$v
                                                  },
                                                  expression: "endDate"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.menu5,
                                  callback: function($$v) {
                                    _vm.menu5 = $$v
                                  },
                                  expression: "menu5"
                                }
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: { "no-title": "", scrollable: "" },
                                    model: {
                                      value: _vm.endDate,
                                      callback: function($$v) {
                                        _vm.endDate = $$v
                                      },
                                      expression: "endDate"
                                    }
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function($event) {
                                            _vm.menu5 = false
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("kai.cancel")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: { click: _vm.dateChanged }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("kai.ok")) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-autocomplete", {
                          staticClass: "v-text-no-field__details",
                          attrs: {
                            outlined: "",
                            dense: "",
                            label: "Country",
                            items: _vm.Countries,
                            "item-text": "Name",
                            "item-value": "Code",
                            "return-object": ""
                          },
                          on: { change: _vm.countryChanged },
                          model: {
                            value: _vm.selectedCountries,
                            callback: function($$v) {
                              _vm.selectedCountries = $$v
                            },
                            expression: "selectedCountries"
                          }
                        }),
                        _c("v-select", {
                          staticClass: "v-text-no-field__details",
                          staticStyle: { "margin-left": "12px" },
                          attrs: {
                            outlined: "",
                            dense: "",
                            label: "Language",
                            items: _vm.langs,
                            "item-text": "Name",
                            "item-value": "Code"
                          },
                          on: { change: _vm.langChanged },
                          model: {
                            value: _vm.selectedLang,
                            callback: function($$v) {
                              _vm.selectedLang = $$v
                            },
                            expression: "selectedLang"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "custom-tab fixed-tab",
                      attrs: { "background-color": "transparent" },
                      on: {
                        change: function($event) {
                          return _vm.tabChanged(_vm.tab)
                        }
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.items, function(item) {
                      return _c("v-tab", { key: item.tab }, [
                        _vm._v(" " + _vm._s(item.tab) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "v-main-content", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg",
                        staticStyle: { "margin-bottom": "100px" },
                        attrs: { elevation: "3" }
                      },
                      [
                        _c(
                          "v-tabs-items",
                          {
                            staticClass: "pt-0 mt-1",
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.items, function(item) {
                            return _c(
                              "v-tab-item",
                              { key: item.id },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col mt-6" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      sm: "12"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pa-0"
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          ref: "searchAppRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            loading:
                                                              _vm.loading,
                                                            items:
                                                              _vm.searchedApps,
                                                            "search-input":
                                                              _vm.search,
                                                            outlined: "",
                                                            "hide-no-data": "",
                                                            "hide-details": "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label: "Search app"
                                                          },
                                                          on: {
                                                            "update:searchInput": function(
                                                              $event
                                                            ) {
                                                              _vm.search = $event
                                                            },
                                                            "update:search-input": function(
                                                              $event
                                                            ) {
                                                              _vm.search = $event
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchAppId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.searchAppId = $$v
                                                            },
                                                            expression:
                                                              "searchAppId"
                                                          }
                                                        }),
                                                        _vm.showAppNameSection
                                                          ? _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "pa-5 d-flex ml-12",
                                                                attrs: {
                                                                  color:
                                                                    "grey lighten-4",
                                                                  elevation: "0"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "closeIcon v-close-top-right pa-0",
                                                                    attrs: {
                                                                      text: "",
                                                                      absolute:
                                                                        "",
                                                                      "min-width":
                                                                        "20px"
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.closeApp
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "success--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "rounded-lg",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "lazy-src":
                                                                      _vm.appIcon,
                                                                    "max-width":
                                                                      "60",
                                                                    "max-height":
                                                                      "60",
                                                                    "min-width":
                                                                      "20",
                                                                    "min-height":
                                                                      "20",
                                                                    src:
                                                                      _vm.appIcon
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 pl-4"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold pb-2 body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.appTitle
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.showAppNameSection
                                                          ? _c("v-divider", {
                                                              staticClass:
                                                                "ml-13 mr-8",
                                                              attrs: {
                                                                vertical: ""
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm.showAppNameSection
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "4",
                                                          md: "4",
                                                          sm: "12"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between align-center total-summary-content flex-wrap mt-7"
                                                          },
                                                          [
                                                            _c(
                                                              "v-sheet",
                                                              {
                                                                staticClass:
                                                                  "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                                attrs: {
                                                                  color:
                                                                    "white",
                                                                  elevation:
                                                                    "0",
                                                                  width: "100%",
                                                                  "max-width":
                                                                    "33.333%"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "top-sheet-col d-flex justify-center flex-column"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "mb-0 pt-2 d-flex justify-space-between"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.appTPK
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.totalPaidKey"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-divider",
                                                                  {
                                                                    staticClass:
                                                                      "ml-8 mr-12",
                                                                    attrs: {
                                                                      vertical:
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "5"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pl-0 pa-3 pr-10"
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass: "medium",
                                                          attrs: {
                                                            outlined: "",
                                                            label:
                                                              "Type a text and press Enter",
                                                            dense: "",
                                                            "prepend-inner-icon":
                                                              "mdi-magnify"
                                                          },
                                                          on: {
                                                            keyup:
                                                              _vm.getDataForKeyword
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchKey,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.searchKey = $$v
                                                            },
                                                            expression:
                                                              "searchKey"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "7"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-space-between align-center total-summary-content flex-wrap mt-7"
                                                      },
                                                      [
                                                        _c(
                                                          "v-sheet",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                            attrs: {
                                                              color: "white",
                                                              elevation: "0",
                                                              width: "100%",
                                                              "max-width": "25%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-center flex-column"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .keywordDetails
                                                                          .popularity
                                                                      ) + " "
                                                                    ),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "ml-3",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "success"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chart-bell-curve-cumulative"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text-2 subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "kai.popularity"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "ml-8 mr-12",
                                                              attrs: {
                                                                vertical: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-sheet",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                            attrs: {
                                                              color: "white",
                                                              elevation: "0",
                                                              width: "100%",
                                                              "max-width": "25%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-center flex-column"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .keywordDetails
                                                                          .noOfApps
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text-2 subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "kai.numOfApps"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "ml-8 mr-12",
                                                              attrs: {
                                                                vertical: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-sheet",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                            attrs: {
                                                              color: "white",
                                                              elevation: "0",
                                                              width: "100%",
                                                              "max-width": "25%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-center flex-column"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .keywordDetails
                                                                          .chance
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text-2 subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "kai.chance"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "ml-8 mr-12",
                                                              attrs: {
                                                                vertical: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-sheet",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                            attrs: {
                                                              color: "white",
                                                              elevation: "0",
                                                              width: "100%",
                                                              "max-width": "25%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-center flex-column"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .keywordDetails
                                                                          .appsRunningAds
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text-2 subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "kai.appsRunAds"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 3
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "8",
                                                      md: "8",
                                                      sm: "12"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pa-3"
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          ref:
                                                            "searchCompeteRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCompetes,
                                                            items:
                                                              _vm.searchedCompetes,
                                                            "search-input":
                                                              _vm.searchCompete,
                                                            outlined: "",
                                                            "hide-no-data": "",
                                                            "hide-details": "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label:
                                                              "Select or search for an app"
                                                          },
                                                          on: {
                                                            "update:searchInput": function(
                                                              $event
                                                            ) {
                                                              _vm.searchCompete = $event
                                                            },
                                                            "update:search-input": function(
                                                              $event
                                                            ) {
                                                              _vm.searchCompete = $event
                                                            },
                                                            change:
                                                              _vm.compChanged
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchCompeteId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.searchCompeteId = $$v
                                                            },
                                                            expression:
                                                              "searchCompeteId"
                                                          }
                                                        }),
                                                        _vm.searchCompeteId
                                                          ? _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "pa-5 d-flex ml-12",
                                                                attrs: {
                                                                  color:
                                                                    "grey lighten-4",
                                                                  elevation: "0"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "closeIcon v-close-top-right pa-0",
                                                                    attrs: {
                                                                      text: "",
                                                                      absolute:
                                                                        "",
                                                                      "min-width":
                                                                        "20px"
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.compCloseApp
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "success--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "rounded-lg",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "lazy-src":
                                                                      _vm.compappIcon,
                                                                    "max-width":
                                                                      "60",
                                                                    "max-height":
                                                                      "60",
                                                                    "min-width":
                                                                      "20",
                                                                    "min-height":
                                                                      "20",
                                                                    src:
                                                                      _vm.compappIcon
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 pl-8"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold pb-2 body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.compappTitle
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 4
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      sm: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pa-3"
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          ref: "searchCatRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCats,
                                                            items:
                                                              _vm.categories,
                                                            outlined: "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label:
                                                              "Select a category"
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.catChanged
                                                          },
                                                          model: {
                                                            value: _vm.selCat,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selCat = $$v
                                                            },
                                                            expression: "selCat"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      sm: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pa-3"
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          ref: "searchCatRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCatTypes,
                                                            items: _vm.catTypes,
                                                            outlined: "",
                                                            dense: "",
                                                            label: "Select type"
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.catTypeChanged
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selCatType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selCatType = $$v
                                                            },
                                                            expression:
                                                              "selCatType"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 5
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "8",
                                                      md: "8",
                                                      sm: "12"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex justify-space-between align-center pa-3"
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          ref: "searchRecomRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            loading:
                                                              _vm.recomSearchLoading,
                                                            items:
                                                              _vm.searchedRecoms,
                                                            "search-input":
                                                              _vm.searchRecom,
                                                            outlined: "",
                                                            "hide-no-data": "",
                                                            "hide-details": "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label:
                                                              "Select or search for an app"
                                                          },
                                                          on: {
                                                            "update:searchInput": function(
                                                              $event
                                                            ) {
                                                              _vm.searchRecom = $event
                                                            },
                                                            "update:search-input": function(
                                                              $event
                                                            ) {
                                                              _vm.searchRecom = $event
                                                            },
                                                            change:
                                                              _vm.recomSearchChanged
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.recomSearchAppId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.recomSearchAppId = $$v
                                                            },
                                                            expression:
                                                              "recomSearchAppId"
                                                          }
                                                        }),
                                                        _vm.recomshowAppNameSection
                                                          ? _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "pa-5 d-flex ml-12",
                                                                attrs: {
                                                                  color:
                                                                    "grey lighten-4",
                                                                  elevation: "0"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "closeIcon v-close-top-right pa-0",
                                                                    attrs: {
                                                                      text: "",
                                                                      absolute:
                                                                        "",
                                                                      "min-width":
                                                                        "20px"
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.recomcloseApp
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "success--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "rounded-lg",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "lazy-src":
                                                                      _vm.recomappIcon,
                                                                    "max-width":
                                                                      "60",
                                                                    "max-height":
                                                                      "60",
                                                                    "min-width":
                                                                      "20",
                                                                    "min-height":
                                                                      "20",
                                                                    src:
                                                                      _vm.recomappIcon
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 pl-8"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold pb-2 body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.recomappTitle
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.recomshowAppNameSection
                                                          ? _c("v-divider", {
                                                              staticClass:
                                                                "ml-13 mr-8",
                                                              attrs: {
                                                                vertical: ""
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm.recomshowAppNameSection
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "4",
                                                          md: "4",
                                                          sm: "12"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between align-center total-summary-content flex-wrap mt-7"
                                                          },
                                                          [
                                                            _c(
                                                              "v-sheet",
                                                              {
                                                                staticClass:
                                                                  "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                                attrs: {
                                                                  color:
                                                                    "white",
                                                                  elevation:
                                                                    "0",
                                                                  width: "100%"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "top-sheet-col d-flex justify-center flex-column"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "mb-0 pt-2 d-flex justify-space-between"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.recomappTPK
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.totalPaidKey"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.recomshowAppNameSection ||
                                    _vm.showAppNameSection
                                      ? _c("v-divider")
                                      : _vm._e(),
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "filter-col py-5" },
                                          [
                                            _c(
                                              "v-item-group",
                                              [
                                                _vm.showAppNameSection
                                                  ? _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            item.id == 1 &&
                                                            _vm.showAppNameSection
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-6",
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openQuickAddWithSource(
                                                                          _vm.filteredkeywordInsights
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus-circle-outline"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "kai.addAllKey"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-content-click": false,
                                                                  "offset-y":
                                                                    "",
                                                                  "min-width":
                                                                    "278",
                                                                  "max-width":
                                                                    "350"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-6",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "bluegrey lighten-4",
                                                                                    outlined:
                                                                                      "",
                                                                                    dark:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "kai.popularity"
                                                                                    )
                                                                                  ) +
                                                                                  " : " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .popularityRange[0]
                                                                                  ) +
                                                                                  " - " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .popularityRange[1]
                                                                                  )
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-chevron-down"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "v-card-title",
                                                                          {
                                                                            staticClass:
                                                                              "body-2 secondary--text pa-0 pt-5 justify-center"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "kai.maxPop"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "text-right body-2 px-0 pt-4"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "v-range-slider",
                                                                              {
                                                                                attrs: {
                                                                                  "thumb-label": true,
                                                                                  step:
                                                                                    "10",
                                                                                  min:
                                                                                    "0",
                                                                                  max:
                                                                                    "100",
                                                                                  "background-color":
                                                                                    "light",
                                                                                  "track-color":
                                                                                    "grey",
                                                                                  "track-fill-color":
                                                                                    "success",
                                                                                  "thumb-color":
                                                                                    "sucess"
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.applyFilter(
                                                                                      0
                                                                                    )
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.popularityRange,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.popularityRange = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "popularityRange"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-content-click": false,
                                                                  "offset-y":
                                                                    "",
                                                                  "max-width":
                                                                    "278"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-6",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "bluegrey lighten-4",
                                                                                    outlined:
                                                                                      "",
                                                                                    dark:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "kai.keyword"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-chevron-down"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-center justify-center v-search-box"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-combobox",
                                                                          {
                                                                            staticClass:
                                                                              "tag-input",
                                                                            attrs: {
                                                                              multiple:
                                                                                "",
                                                                              label:
                                                                                "Enter Keywords",
                                                                              "append-icon":
                                                                                "",
                                                                              chips:
                                                                                "",
                                                                              "deletable-chips":
                                                                                "",
                                                                              "search-input":
                                                                                _vm.tagSearch
                                                                            },
                                                                            on: {
                                                                              "update:searchInput": function(
                                                                                $event
                                                                              ) {
                                                                                _vm.tagSearch = $event
                                                                              },
                                                                              "update:search-input": function(
                                                                                $event
                                                                              ) {
                                                                                _vm.tagSearch = $event
                                                                              },
                                                                              change:
                                                                                _vm.updateTags,
                                                                              keyup: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                ) {
                                                                                  return null
                                                                                }
                                                                                return _vm.updateTags.apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.tags,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.tags = $$v
                                                                              },
                                                                              expression:
                                                                                "tags"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                            attrs: {
                                                                              color:
                                                                                "success",
                                                                              depressed:
                                                                                "",
                                                                              dense:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.applyFilter(
                                                                                  1
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "kai.clear"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            !(
                                                              _vm.tags.length ==
                                                                0 &&
                                                              _vm
                                                                .popularityRange[0] ==
                                                                0 &&
                                                              _vm
                                                                .popularityRange[1] ==
                                                                100
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      depressed:
                                                                        "",
                                                                      dense: "",
                                                                      text: "",
                                                                      color:
                                                                        "error"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.applyFilter(
                                                                          1
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-0",
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-delete-outline"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-decoration-underline"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.clrAllFilter"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 5 &&
                                    _vm.recomfilteredkeywordInsights.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "filter-col py-5" },
                                          [
                                            _c(
                                              "v-item-group",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                        staticStyle: {
                                                          display:
                                                            "flex !important"
                                                        },
                                                        attrs: {
                                                          cols: "6",
                                                          md: "6",
                                                          sm: "12"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "medium",
                                                            attrs: {
                                                              dark: "",
                                                              color:
                                                                "mr-2 green lighten-6 success--text",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openQuickAddWithSource(
                                                                  _vm.recomfilteredkeywordInsights
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-plus-circle-outline"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "kai.addAllKey"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          md: "6",
                                                          sm: "12"
                                                        }
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          ref: "searchRecomRef",
                                                          refInFor: true,
                                                          staticClass: "medium",
                                                          attrs: {
                                                            dense: "",
                                                            label:
                                                              "Search below table",
                                                            "append-icon":
                                                              "mdi-magnify"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchRecoms,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.searchRecoms = $$v
                                                            },
                                                            expression:
                                                              "searchRecoms"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("div"),
                                    item.id == 1
                                      ? _c("v-data-table", {
                                          staticClass: "elevation-0",
                                          attrs: {
                                            search: _vm.searchKeys,
                                            headers: _vm.headers,
                                            items: _vm.filteredkeywordInsights,
                                            loading: _vm.loadingKeys,
                                            "loading-text":
                                              "Loading keywords - Just a moment",
                                            "no-data-text": _vm.noDataTextKeys,
                                            page: _vm.page,
                                            options: _vm.options,
                                            "items-per-page": _vm.itemsPerPage,
                                            "hide-default-footer": ""
                                          },
                                          on: {
                                            "update:page": function($event) {
                                              _vm.page = $event
                                            },
                                            "update:options": function($event) {
                                              _vm.options = $event
                                            },
                                            "page-count": function($event) {
                                              _vm.pageCount = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.plus",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-2 pa-0",
                                                        attrs: {
                                                          width: "22",
                                                          height: "18",
                                                          "min-width": "20",
                                                          text: "",
                                                          depressed: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.plusKeyClicked(
                                                              item["name"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "success--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-plus-circle-outline"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function(ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.name",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-4 d-flex my-5 rounded-lg",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.keyClicked(
                                                              item["name"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 pa-0 secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount },
                                              model: {
                                                value: _vm.page,
                                                callback: function($$v) {
                                                  _vm.page = $$v
                                                },
                                                expression: "page"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c("v-data-table", {
                                          staticClass: "elevation-0 mt-4",
                                          attrs: {
                                            search: _vm.searchApps,
                                            headers: _vm.appheaders,
                                            items: _vm.applicationInsights,
                                            loading: _vm.loadingApps,
                                            "loading-text":
                                              "Loading Apps... Please wait",
                                            "no-data-text": _vm.noDataTextApps,
                                            page: _vm.page2,
                                            options: _vm.options2,
                                            "items-per-page": _vm.itemsPerPage2,
                                            "hide-default-footer": ""
                                          },
                                          on: {
                                            "update:page": function($event) {
                                              _vm.page2 = $event
                                            },
                                            "update:options": function($event) {
                                              _vm.options2 = $event
                                            },
                                            "page-count": function($event) {
                                              _vm.pageCount2 = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.sno",
                                                fn: function(ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-4 d-flex my-5 rounded-lg",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.appClicked(
                                                              item[
                                                                "appDetails"
                                                              ],
                                                              item["appId"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.appDetails
                                                                .icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src:
                                                              item.appDetails
                                                                .icon
                                                          }
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-4"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 font-weight-bold pa-0 secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .appDetails
                                                                        .title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .appDetails
                                                                      .title
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.is",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-progress-circular",
                                                      {
                                                        attrs: {
                                                          rotate: 90,
                                                          size: 50,
                                                          width: 5,
                                                          value: item["is"],
                                                          color: "error"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item["is"]) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount2 },
                                              model: {
                                                value: _vm.page2,
                                                callback: function($$v) {
                                                  _vm.page2 = $$v
                                                },
                                                expression: "page2"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 3
                                      ? _c("v-data-table", {
                                          staticClass: "elevation-0 mt-4",
                                          attrs: {
                                            headers: _vm.compHeaders,
                                            items: _vm.comps,
                                            loading: _vm.compsLoading,
                                            "loading-text":
                                              "Loading Apps... Please wait",
                                            "no-data-text": _vm.noDataTextApps,
                                            "hide-default-footer": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.sno",
                                                fn: function(ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-4 d-flex my-5 rounded-lg",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.compAppClicked(
                                                              item["title"],
                                                              item["id"],
                                                              item["icon"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src: item.icon
                                                          }
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 font-weight-bold pa-0 secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    item.id == 4
                                      ? _c("v-data-table", {
                                          staticClass: "elevation-0 mt-0",
                                          attrs: {
                                            search: _vm.searchTops,
                                            headers: _vm.catTopHeaders,
                                            items: _vm.catTop,
                                            loading: _vm.catTopLoading,
                                            "loading-text":
                                              "Loading Apps... Please wait",
                                            "no-data-text": _vm.noDataTextApps,
                                            "items-per-page": 100,
                                            page: _vm.page3,
                                            options: _vm.options3,
                                            "hide-default-footer": ""
                                          },
                                          on: {
                                            "update:page": function($event) {
                                              _vm.page3 = $event
                                            },
                                            "update:options": function($event) {
                                              _vm.options3 = $event
                                            },
                                            "page-count": function($event) {
                                              _vm.pageCount3 = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.sno",
                                                fn: function(ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-4 d-flex my-5 rounded-lg",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.compAppClicked(
                                                              item["title"],
                                                              item["id"],
                                                              item["icon"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src: item.icon
                                                          }
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 font-weight-bold pa-0 secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    item.id == 4
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount3 },
                                              model: {
                                                value: _vm.page3,
                                                callback: function($$v) {
                                                  _vm.page3 = $$v
                                                },
                                                expression: "page3"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 5
                                      ? _c("v-data-table", {
                                          staticClass: "elevation-0 mt-4",
                                          attrs: {
                                            search: _vm.searchRecoms,
                                            headers: _vm.recomheaders,
                                            items:
                                              _vm.recomfilteredkeywordInsights,
                                            loading: _vm.recomloadingKeys,
                                            "loading-text":
                                              "Loading keywords - Just a moment",
                                            "no-data-text": _vm.noDataTextKeys,
                                            page: _vm.page4,
                                            options: _vm.options4,
                                            "items-per-page": _vm.itemsPerPage,
                                            "hide-default-footer": ""
                                          },
                                          on: {
                                            "update:page": function($event) {
                                              _vm.page4 = $event
                                            },
                                            "update:options": function($event) {
                                              _vm.options4 = $event
                                            },
                                            "page-count": function($event) {
                                              _vm.pageCount4 = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.plus",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-2 pa-0",
                                                        attrs: {
                                                          width: "22",
                                                          height: "18",
                                                          "min-width": "20",
                                                          text: "",
                                                          depressed: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.plusKeyClicked(
                                                              item["name"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "success--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-plus-circle-outline"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function(ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.name",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-4 d-flex my-5 rounded-lg",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.keyClicked(
                                                              item["name"]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 pa-0 secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _vm._e(),
                                    item.id == 5
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount4 },
                                              model: {
                                                value: _vm.page4,
                                                callback: function($$v) {
                                                  _vm.page4 = $$v
                                                },
                                                expression: "page4"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-snackbar",
                  {
                    attrs: {
                      "max-width": "500",
                      timeout: -1,
                      value: _vm.progress,
                      bottom: true,
                      right: true,
                      rounded: "",
                      color: "primary",
                      elevation: "12",
                      vertical: true
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.progressText) +
                        " (" +
                        _vm._s(_vm.gotSoFar) +
                        " of " +
                        _vm._s(_vm.outOf) +
                        ") "
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "white", text: "" },
                        on: { click: _vm.closeProgress }
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                    _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: {
                        color: "white",
                        "buffer-value": _vm.progressBufValue,
                        value: _vm.progressValue,
                        stream: ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-dialog",
                  {
                    staticClass: "quick-add-criteria",
                    attrs: { persistent: "", "max-width": "600px" },
                    model: {
                      value: _vm.quickAddDialog,
                      callback: function($$v) {
                        _vm.quickAddDialog = $$v
                      },
                      expression: "quickAddDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "titleWithClose d-flex justify-space-between mb-4"
                          },
                          [
                            _c("span", { staticClass: "text-title" }, [
                              _vm._v("Quick Add Criteria")
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.quickAddDialog = false
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "pb-0" },
                          [
                            _c(
                              "v-container",
                              [
                                _vm.tab == 0
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-medium primary--text"
                                              },
                                              [_vm._v("Organic Rank")]
                                            ),
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: { row: "" },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.organicCriteriaSelected(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.quickAddModel
                                                      .organicRank.criteria,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.quickAddModel
                                                        .organicRank,
                                                      "criteria",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quickAddModel.organicRank.criteria"
                                                }
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Less Than",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .LESS_THAN
                                                  }
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Greater Than",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .GREATER_THAN
                                                  }
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Equals to",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .EQUALS
                                                  }
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "Between",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .IN_RANGE
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.isOrganicRange === true
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "From",
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.from,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "from",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.from"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isOrganicRange === true
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "To",
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.to,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "to",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.to"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isOrganicRange === false
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Value",
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.value"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-divider", { staticClass: "mb-3" }),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-weight-medium primary--text"
                                          },
                                          [_vm._v("Popularity")]
                                        ),
                                        _c(
                                          "v-radio-group",
                                          {
                                            attrs: { row: "" },
                                            on: {
                                              change: function($event) {
                                                return _vm.popularityCriteriaSelected(
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.quickAddModel.popularity
                                                  .criteria,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.quickAddModel.popularity,
                                                  "criteria",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "quickAddModel.popularity.criteria"
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Less Than",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .LESS_THAN
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Greater Than",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .GREATER_THAN
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Equals to",
                                                value:
                                                  _vm.criteriaOptionsEnum.EQUALS
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Between",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .IN_RANGE
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.isPopularityRange === true
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "From",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.popularity
                                                    .from,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel
                                                      .popularity,
                                                    "from",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.popularity.from"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isPopularityRange === true
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "To",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.popularity
                                                    .to,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel
                                                      .popularity,
                                                    "to",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.popularity.to"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isPopularityRange === false
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Value",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.popularity
                                                    .value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel
                                                      .popularity,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.popularity.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mb-3" }),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-weight-medium primary--text"
                                          },
                                          [_vm._v("Total Apps")]
                                        ),
                                        _c(
                                          "v-radio-group",
                                          {
                                            attrs: { row: "" },
                                            on: {
                                              change: function($event) {
                                                return _vm.appsCriteriaSelected(
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.quickAddModel.totalApps
                                                  .criteria,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.quickAddModel.totalApps,
                                                  "criteria",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "quickAddModel.totalApps.criteria"
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Less Than",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .LESS_THAN
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Greater Than",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .GREATER_THAN
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Between",
                                                value:
                                                  _vm.criteriaOptionsEnum
                                                    .IN_RANGE
                                              }
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Equals to",
                                                value:
                                                  _vm.criteriaOptionsEnum.EQUALS
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.isAppsRange === true
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "From",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.totalApps
                                                    .from,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel.totalApps,
                                                    "from",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.totalApps.from"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isAppsRange === true
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "To",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.totalApps
                                                    .to,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel.totalApps,
                                                    "to",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.totalApps.to"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isAppsRange === false
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Value",
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.quickAddModel.totalApps
                                                    .value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.quickAddModel.totalApps,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quickAddModel.totalApps.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end px-8 pt-6 pb-8" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "black", outlined: "" },
                                on: {
                                  click: function($event) {
                                    _vm.quickAddDialog = false
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", elevation: "0" },
                                on: {
                                  click: function($event) {
                                    return _vm.addAllKeyClicked()
                                  }
                                }
                              },
                              [_vm._v(" Add Keywords ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.loadingApps ||
              _vm.loadingKeys ||
              _vm.compsLoading ||
              _vm.recomloadingKeys
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: "362" },
                      model: {
                        value: _vm.loadingAlertFlag,
                        callback: function($$v) {
                          _vm.loadingAlertFlag = $$v
                        },
                        expression: "loadingAlertFlag"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticStyle: { padding: "7px 0px" },
                          attrs: { color: "primary" }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "cust",
                              attrs: { color: "primary" }
                            },
                            [
                              _c("label", { staticStyle: { color: "white" } }, [
                                _vm._v(
                                  " Fetching lots of data for you. Please stand by"
                                )
                              ]),
                              _c("v-progress-linear", {
                                staticClass: "mb-0",
                                attrs: {
                                  indeterminate: "",
                                  color: "white",
                                  stream: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }