export const aptweak_categories = {
  0 : 'All',
  6018 : 'Books',
  6014 : 'Games',
  6010 : 'Navigation',
  6023 : 'Food & Drink',
  6016 : 'Entertainment',
  6008 : 'Photo & Video',
  6000 : 'Business',
  6022 : 'Catalogs',
  6017 : 'Entertainment',
  6015 : 'Finance',
  6013 : 'Health & Fitness',
  6012 : 'Lifestyle',
  6020 : 'Medical',
  6011 : 'Music',
  6009 : 'News',
  6021 : 'Newsstand',
  6007 : 'Productivity',
  6006 : 'Reference',
  6005 : 'Social Networking',
  6024 : 'Shopping',
  6004 : 'Sports',
  6003 : 'Travel',
  6002 : 'Utilities',
  6001 : 'Weather',

  7001 : 'Game Action',
  7002 : 'Game Adventure',
  7003 : 'Game Casual',
  7004 : 'Game Board',
  7005 : 'Game Card',
  7006 : 'Game Casino',
  7007 : 'Game Dice',
  7008 : 'Game Educational',
  7009 : 'Game Family',
  7010 : 'Game Kids',
  7011 : 'Game Music',
  7012 : 'Game Puzzle',
  7013 : 'Game Racing',
  7014 : 'Game Role Playing',
  7015 : 'Game Simulation',
  7016 : 'Game Sports',
  7017 : 'Game Strategy',
  7018 : 'Game Trivia',
  7019 : 'Game Word',
}



export const at_cats = [
  {
      "id": "0",
      "title": "All"
  },
  {
      "id": "6000",
      "title": "Business"
  },
  {
      "id": "6001",
      "title": "Weather"
  },
  {
      "id": "6002",
      "title": "Utilities"
  },
  {
      "id": "6003",
      "title": "Travel"
  },
  {
      "id": "6004",
      "title": "Sports"
  },
  {
      "id": "6005",
      "title": "Social Networking"
  },
  {
      "id": "6006",
      "title": "Reference"
  },
  {
      "id": "6007",
      "title": "Productivity"
  },
  {
      "id": "6008",
      "title": "Photo & Video"
  },
  {
      "id": "6009",
      "title": "News"
  },
  {
      "id": "6010",
      "title": "Navigation"
  },
  {
      "id": "6011",
      "title": "Music"
  },
  {
      "id": "6012",
      "title": "Lifestyle"
  },
  {
      "id": "6013",
      "title": "Health & Fitness"
  },
  {
      "id": "6014",
      "title": "Games"
  },
  {
      "id": "6015",
      "title": "Finance"
  },
  {
      "id": "6016",
      "title": "Entertainment"
  },
  {
      "id": "6017",
      "title": "Entertainment"
  },
  {
      "id": "6018",
      "title": "Books"
  },
  {
      "id": "6020",
      "title": "Medical"
  },
  {
      "id": "6021",
      "title": "Newsstand"
  },
  {
      "id": "6022",
      "title": "Catalogs"
  },
  {
      "id": "6023",
      "title": "Food & Drink"
  },
  {
      "id": "6024",
      "title": "Shopping"
  },
  {
      "id": "7001",
      "title": "Game Action"
  },
  {
      "id": "7002",
      "title": "Game Adventure"
  },
  {
      "id": "7003",
      "title": "Game Casual"
  },
  {
      "id": "7004",
      "title": "Game Board"
  },
  {
      "id": "7005",
      "title": "Game Card"
  },
  {
      "id": "7006",
      "title": "Game Casino"
  },
  {
      "id": "7007",
      "title": "Game Dice"
  },
  {
      "id": "7008",
      "title": "Game Educational"
  },
  {
      "id": "7009",
      "title": "Game Family"
  },
  {
      "id": "7010",
      "title": "Game Kids"
  },
  {
      "id": "7011",
      "title": "Game Music"
  },
  {
      "id": "7012",
      "title": "Game Puzzle"
  },
  {
      "id": "7013",
      "title": "Game Racing"
  },
  {
      "id": "7014",
      "title": "Game Role Playing"
  },
  {
      "id": "7015",
      "title": "Game Simulation"
  },
  {
      "id": "7016",
      "title": "Game Sports"
  },
  {
      "id": "7017",
      "title": "Game Strategy"
  },
  {
      "id": "7018",
      "title": "Game Trivia"
  },
  {
      "id": "7019",
      "title": "Game Word"
  }
]
