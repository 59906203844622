




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
  import * as _ from 'lodash';
  import Vue from 'vue';
  import { ATCODES } from '../utils/at-codes';
  import { aptweak_categories, at_cats } from '../utils/aptweak-categories';
  import { APP_KEYWORDS_FULL, SEARCH_BY_APP, SEARCH_BY_KEYWORD, SEARCH_FOR_APPS, SEARCH_FOR_COMPETES, OWN_APPS, GET_CATEGORY_TOP, KEYWORD_RECOMENDS, GET_RECOM_LIST, APP_METADATA, UPDATE_CHECKLIST} from '../utils/apptweakqueries';
  import SelectAdGroup from '../components/keywords/SelectAdGroup.vue';
  import AddKeysKai from '../components/keywords/AddKeysKai.vue';
  import { LSS } from '@core/services/localstorage.service'
  import {
    ADD_KEYWORDS,
  } from '../utils/apollo-mutations';
  import io from 'socket.io-client';
  import { API_WS_URL } from '@config';
  import { updateCLF } from '../utils/util';
  import { KEYWORDS_PER_TRY } from '@config';

  enum CriteriaOptions {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IN_RANGE = 'IN_RANGE',
  EQUALS = 'EQUALS'
}

  export default Vue.extend({
    components: {
      SelectAdGroup,
      AddKeysKai,
    },
    data () {
      return {
        selectedLang: null,
        langs: null,
        loadingAlertFlag: true,
        criteriaOptionsEnum: CriteriaOptions,
        loadingAlertText: 'Fetching data... Please stand by',
        opId: 0,
        clientId: 0,
        compappIcon: '',
        compappTitle: '',
        searchTops: '',
        searchRecoms: '',
        recomsLoading: false,
        recoms: [],
        recomSearchLoading: false,
        searchedRecoms: [],
        recomSearchAppId: null,
        catTop: [],
        catTopLoading: false,
        selCatType: this.$t('kai.free'),
        loadingCatTypes: false,
        catTypes: [ this.$t('kai.free'), this.$t('kai.paid')],
        selCat: "6014",
        loadingCats: false,
        categories: [],
        compsLoading: false,
        compId:null,
        comps: [],
        searchCompeteId: null,
        prevSearchCompeteId: null,
        searchedCompetes: [],
        loadingCompetes: false,
        ownApps: [],
        progressText: 'Fetching more data in the background...',
        progressErrCount: 0,
        progress: false,
        progressValue: 0,
        progressBufValue: 0,
        gotSoFar: 0,
        socket: null,
        outOf: 0,
        fromDb: false,
        tags: [],
        tagSearch: '',
        language: '',
        device: '',
        selectedCountries: null,
        Countries: null,
        showAppNameSection: false,
        adgroupMetadata : null,
        selectAdgroupForKeywords: null,
        currency: 'USD',
        matchTypes: ['EXACT', 'BROAD'],
        addedKeywords: [],
        sourceKeywords: [],
        showAddedKeyList: false,
        disableAppSearch: true,
        orgId: '',
        media : 50,
        numbers : 0,
        csName : '',
        drawer: true,
        timeProps: {
            outlined: true,
            dense: true,
        },
        options: {

        },
        options2: {

        },
        options3: {

        },
        options4: {

        },
        currPage: 0,
        currPage2: 0,
        country: [{
          name:'India', code:'in'
        },
        {
          name:'United States', code:'us'
        },
        {
          name:'United Kingdom', code:'uk'
        },
        {
          name:'Australia', code:'au'
        }],
        mini: true,
        e1: 1,
        tab: null,
        items: [
          { tab: this.$t('kai.searchByApp'), content: 'Search by App', id : 1 },
          { tab: this.$t('kai.searchByKey'), content: 'Search by Keywords', id : 2  },
          { tab: this.$t('kai.searchForCompetes'), content: 'Competitors', id : 3  },
          { tab: this.$t('kai.categoryTop'), content: 'Category Top', id : 4  },
          { tab: this.$t('kai.searchAdsRecommends'), content: 'Search Ads Recommendations', id : 5  },
        ],
        date: new Date().toISOString().substr(0, 10),
        enddate: new Date().toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        menu5: false,
        quickAddDialog: false,
        quickAddFilterOptions: [
          {
            text: 'Greater Than',
            value: CriteriaOptions.GREATER_THAN
          },
          {
            text: 'Less Than',
            value: CriteriaOptions.LESS_THAN
          },
          {
            text: 'In Range',
            value: CriteriaOptions.IN_RANGE
          }
        ],
        isOrganicRange: undefined,
        isPopularityRange: undefined,
        isAppsRange: undefined,

        quickAddModel: {
          // keys are taken from the response
          organicRank: {
            criteria: null,
            value: null,
            from: null,
            to: null
          },
          popularity: {
            criteria: null,
            value: null,
            from: null,
            to: null
          },
          totalApps: {
            criteria: null,
            value: null,
            from: null,
            to: null
          }
        },

        // Data table
        headers: [
            {
              text: '',
              align: 'start',
              value: 'plus',
              sortable: false
            },
            // { text: 'S.No', value: 'sno' },
            { text: this.$t('kai.keywords'), value: 'name' },
            //{ text: 'Imp Share', value: 'impshare', default: 'NA' },
            //{ text: 'Others', value: 'others', default: 'NA' },
            { text: this.$t('kai.organicRank'), value: 'organicRank', default: 'NA' },
            { text: this.$t('kai.popularity'), value: 'popularity', default: 'NA' },
            { text: this.$t('kai.totalApps'), value: 'totalApps', default: 'NA' },
        ],
        recomheaders: [
            {
              text: '',
              align: 'start',
              value: 'plus',
              sortable: false
            },
            // { text: 'S.No', value: 'sno' },
            { text: this.$t('kai.keywords'), value: 'name' },
            { text: this.$t('kai.popularity'), value: 'popularity', default: 'NA' },
            { text: this.$t('kai.totalApps'), value: 'totalApps', default: 'NA' },
            { text: this.$t('kai.monthlyDownloads'), value: 'totalDownloads', default: 'NA' },
        ],
        loadingKeys : false,
        loadingApps : false,
        search : '',
        popularityRange: [0, 100],
        keyFilter : '',
        searchKeys: '',
        searchApps: '',
        searchedApps: [],
        loading : false,
        selectedApp: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 100,
        noDataTextKeys : 'No data to show',
        page2: 1,
        pageCount2: 0,
        page3: 1,
        pageCount3: 0,
        page4: 1,
        pageCount4: 0,
        itemsPerPage2: 100,
        noDataTextApps : 'No data to show',
        appheaders: [
          // { text: 'S.No', value: 'sno' },
          { text: this.$t('kai.app'), value: 'appDetails' },
          //{ text: 'Imp. Share', value: 'is', default: 'NA' },
          { text: this.$t('kai.organicRank'), value: 'organicRank', default: '-'},
          { text: this.$t('kai.totalPaidKey'), value: 'noOfKeywords', default: 'NA' },
          { text: this.$t('kai.catName'), value: 'category' },
        ],
        compHeaders: [
          // { text: 'S.No', value: 'sno' },
          { text: this.$t('kai.app'), value: 'appDetails' },
          { text: this.$t('kai.compRating'), value: 'rating' },
          { text: this.$t('kai.compVersion'), value: 'version' },
          { text: this.$t('kai.compPrice'), value: 'price' },
          { text: this.$t('kai.compGenres'), value: 'genres' },
        ],
        catTopHeaders: [
          // { text: 'S.No', value: 'sno' },
          { text: this.$t('kai.app'), value: 'appDetails' },
          { text: this.$t('kai.compRating'), value: 'rating' },
          { text: this.$t('kai.compPrice'), value: 'price' },
          { text: this.$t('kai.compGenres'), value: 'genres' },
        ],
        recomHeaders: [
          {
            text: '',
            align: 'start',
            value: 'plus',
            sortable: false
          },
          // { text: 'S.No', value: 'sno' },
          { text: this.$t('kai.keyword'), value: 'keyword' },
          { text: this.$t('kai.popularity'), value: 'popularity' },
        ],
        allApps : [],
        applicationInsights: [],
        keywordDetails: {
          popularity : 0,
          noOfApps: 0,
          appsRunningAds: 0,
          chance: 0
        },
        searchKey : '',
        startDate : '2021-03-01',
        endDate : new Date().toISOString().substr(0, 10),
        appIds : [],
        appIdsLimited : [],
        allRanks : [],
        searchApp : '',
        searchCompete : '',
        searchRecom : '',
        searchAppId : 604044220,
        prevSearchAppId : 604044220,
        appIcon : '',
        appTitle : '',
        appSubTitle : '',
        appTPK : 0,
        appAC : 0,
        allKeywords : [],
        fewKeywords : [],
        keywordInsights : [],
        filteredkeywordInsights : [],
        allAppKeyRanks : [],

        recomappIcon : '',
        recomappTitle : '',
        recomappSubTitle : '',
        recomappTPK : 0,
        recomappAC : 0,
        recomkeywordInsights : [],
        recomfilteredkeywordInsights : [],
        recomloadingKeys: false,
        recomshowAppNameSection: false,
        recomoutOf: 0,
      }
    },
    methods: {
      organicCriteriaSelected(value) {
        this.isOrganicRange = value === CriteriaOptions.IN_RANGE;
      },
      popularityCriteriaSelected(value) {
        this.isPopularityRange = value === CriteriaOptions.IN_RANGE;
      },
      appsCriteriaSelected(value) {
        this.isAppsRange = value === CriteriaOptions.IN_RANGE;
      },
      closeProgress(){
        if(this.socket) this.socket.disconnect()
        this.progress = false
      },
      recomSearchChanged(e){
        this.recomcloseApp()
        if(e !== null && e !== '') {
          this.recomSearchAppId = e
          this.getRecomKeysForApp(e);
        }
      },
      catChanged(e){
        //console.log(e)
        this.getCategoryTop()
      },
      catTypeChanged(e){
        //console.log(e)
        this.getCategoryTop()
      },
      compChanged(e){
        //console.log(e)
        this.comps = []
        this.compappTitle = '';
        this.compappIcon = '';
        if(e !== null && e !== '') {
          this.getAppMetada(e)
          this.searchCompeteId = e
          this.searchForCompetes(e);
        }
      },
      getAppMetada(e){
        this.$apollo
        .query({
          query: APP_METADATA,
          variables: {
            appId : e,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            startDate: this.startDate,
            endDate: this.endDate
          }
        })
        .then(data => {
          //console.log(data.data);
          if(data.data.getAppMetadata == null || data.data.getAppMetadata == undefined) {
            return;
          }
          this.compappTitle = data.data.getAppMetadata.content.title;
          this.compappIcon = data.data.getAppMetadata.content.icon;
        })
        .catch(error => {
          //console.log(error);
        });
      },
      compAppClicked(title,id,icon){
        //console.log(title,id,icon)
        this.closeApp()
        this.searchAppId = id;
        this.searchApp = title;
        this.tab = 0;
        const fakeEvent = {
          keyCode : 13
        }
        this.getKeysForApp();
      },
      getCompInsights(e){
        console.log(e)
      },
      updateTags() {
        //console.log('updateTags', this.tags);
        this.applyFilter(0);
      },
      getKeysForApp() {
          if(this.socket) this.socket.disconnect()
          this.progress = false
          this.fromDb = true
          this.gotSoFar = 10
          this.clientId = Date.now()
          //console.log(this.clientId)
          this.outOf = 100
          this.progressValue = 0
          this.progressBufValue = 0
          this.progressErrCount = 0
          this.loadingKeys = true;
          this.keywordInsights = [];
          this.filteredkeywordInsights = [];

          this.socket = io(API_WS_URL)

          this.socket.on("connect", () => {
            //console.log(this.socket.id);
          });

          this.socket.on("error", (error) => {
            if(this.socket) this.socket.disconnect()
            this.progress = false
            //console.log('Socket error');
          });

          this.socket.on('msgToClient', (message) => {
            //console.log(message)
            if(message.clientId != this.clientId || message.opId != 1 || message.appId != this.searchAppId) return
            //if(message.appId != this.searchAppId) return
            //this.fromDb = message.fromDB
            if(message.data == 'over'){
              this.progress = false
              this.socket.disconnect()
              return
            }
            this.gotSoFar = this.gotSoFar + 10
            this.progressValue = this.progressValue + (1000/this.appTPK)
            this.progressBufValue = this.progressValue + (1000/this.appTPK)
            if(message.err){
              this.progressErrCount = this.progressErrCount + 10
              return
            }
            const keys = JSON.parse(message.data)
            //console.log(keys)
            //this.progressText = 'Fetching more keywords... '+gotSoFar.toString()+'/'+this.appTPK.toString(),
            keys.map(x => {
              //console.log(x)
              //this.keywordInsights.push(x)
              this.filteredkeywordInsights.push(x)
            })
          })

          this.socket.on("disconnect", () => {
            //this.progressText = 'Server disconnected...'
            this.progress = false
            //console.log('Client Disconnected');
          });

          //this.socket.emit('msgToServer', 'Hello')

          this.$apollo
          .query({
            query: SEARCH_BY_APP,
            variables: {
              appId : this.searchAppId,
              opId: 1,
              clientId: this.clientId,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              startDate: this.startDate,
              endDate: this.endDate,
              currPage: Date.now(),
              itemsPerPage: 10
            }
          })
          .then(data => {
            //console.log(data.data);
            if(data.data.searchByApp == null || data.data.searchByApp == undefined) {
              this.loadingKeys = false;
              this.noDataTextKeys = 'No data to show';
              return;
            }
            updateCLF('kaiApp', this)
            this.keywordInsights = data.data.searchByApp.keywords;
            this.appTitle = data.data.searchByApp.details.title;
            this.appIcon = data.data.searchByApp.details.icon;
            this.appSubTitle = data.data.searchByApp.details.subtitle;
            this.appTPK = data.data.searchByApp.count;
            this.showAppNameSection = true;
            this.filteredkeywordInsights = data.data.searchByApp.keywords;
            this.loadingKeys = false;
            this.outOf = this.appTPK
            this.progressValue = (1000/this.appTPK)
            this.progressBufValue = this.progressValue + (1000/this.appTPK)
            this.fromDb = data.data.searchByApp.fromDB;
            if(!this.fromDb) {
              this.progress = true
              const c = {
                done: false,
                preText : 'Fetching',
                text: ' Keywords for App - '+this.appTitle,
                path: 'keyword-auction-insights',
                query: 'app',
                icon: 'mdi-format-list-bulleted',
                disabled: true,
                progress: (1000/this.appTPK),
                total: this.appTPK,
                fetched: 10,
                clientId: this.clientId,
                appId : this.searchAppId,
                opId: 1,
                keyword: 'searching for an app'
              }
              this.$root.$emit('addDataRequests', c)
            }else{
              this.progress = false
              this.socket.disconnect()
            }
          })
          .catch(error => {
            //console.log(error);
            this.progress = false
            this.loadingKeys = false;
            this.noDataTextKeys = 'No data to show';
          });
      },
      async getRecomKeysForApp() {
          // if(LSS.token && decodeToken(LSS.token).checkList){
          //   if(!(decodeToken(LSS.token).checkList.kaiRecom)) await this.updateCL('kaiRecom')
          // }
          if(this.socket) this.socket.disconnect()
          this.progress = false
          this.fromDb = true
          this.gotSoFar = 10
          this.clientId = Date.now()
          //console.log(this.clientId)
          this.outOf = 100
          this.progressValue = 0
          this.progressBufValue = 0
          this.progressErrCount = 0
          this.recomloadingKeys = true;
          this.recomkeywordInsights = [];
          this.recomfilteredkeywordInsights = [];

          this.socket = io(API_WS_URL)

          this.socket.on("connect", () => {
            //console.log(this.socket.id);
          });

          this.socket.on("error", (error) => {
            if(this.socket) this.socket.disconnect()
            this.progress = false
            //console.log('Socket error');
          });

          this.socket.on('msgToClient', (message) => {
            //console.log(message)
            if(message.clientId != this.clientId || message.opId != 3 || message.appId != this.recomSearchAppId) return
            //if(message.appId != this.recomSearchAppId) return
            //this.fromDb = message.fromDB
            if(message.data == 'over'){
              this.progress = false
              this.socket.disconnect()
              return
            }
            this.gotSoFar = this.gotSoFar + 10
            this.progressValue = this.progressValue + (1000/this.recomappTPK)
            this.progressBufValue = this.progressValue + (1000/this.recomappTPK)
            if(message.err){
              this.progressErrCount = this.progressErrCount + 10
              return
            }
            const keys = JSON.parse(message.data)
            //console.log(keys)
            //this.progressText = 'Fetching more keywords... '+gotSoFar.toString()+'/'+this.appTPK.toString(),
            keys.map(x => {
              //console.log(x)
              //this.keywordInsights.push(x)
              this.recomfilteredkeywordInsights.push(x)
            })
          })

          this.socket.on("disconnect", () => {
            //this.progressText = 'Server disconnected...'
            this.progress = false
            //console.log('Client Disconnected');
          });

          //this.socket.emit('msgToServer', 'Hello')

          this.$apollo
          .query({
            query: GET_RECOM_LIST,
            variables: {
              appId : this.recomSearchAppId,
              opId: 3,
              clientId: this.clientId,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              startDate: this.startDate,
              endDate: this.endDate,
              currPage: Date.now(),
              itemsPerPage: 10
            }
          })
          .then(data => {
            //console.log(data.data);
            if(data.data.getRecommendationList == null || data.data.getRecommendationList == undefined) {
              this.recomloadingKeys = false;
              this.noDataTextKeys = 'No data to show';
              return;
            }
            updateCLF('kaiRecom', this)
            this.recomkeywordInsights = data.data.getRecommendationList.keywords;
            this.recomappTitle = data.data.getRecommendationList.details.title;
            this.recomappIcon = data.data.getRecommendationList.details.icon;
            this.recomappSubTitle = data.data.getRecommendationList.details.subtitle;
            this.recomappTPK = data.data.getRecommendationList.count;
            this.recomshowAppNameSection = true;
            this.recomfilteredkeywordInsights = data.data.getRecommendationList.keywords;
            this.recomloadingKeys = false;
            this.outOf = this.recomappTPK
            this.progressValue = (1000/this.recomappTPK)
            this.progressBufValue = this.progressValue + (1000/this.recomappTPK)
            this.fromDb = data.data.getRecommendationList.fromDB;
            if(!this.fromDb) {
              this.progress = true
              const c = {
                done: false,
                preText : 'Fetching',
                text: ' Recommendations for App - '+this.recomappTitle,
                path: 'keyword-auction-insights',
                query: 'recom',
                icon: 'mdi-format-list-bulleted',
                disabled: true,
                progress: (1000/this.recomappTPK),
                total: this.recomappTPK,
                fetched: 10,
                clientId: this.clientId,
                appId : this.recomSearchAppId,
                opId: 3,
                keyword: 'searching for recommendations'
              }
              this.$root.$emit('addDataRequests', c)
            }else{
              this.progress = false
              this.socket.disconnect()
            }
          })
          .catch(error => {
            //console.log(error);
            this.progress = false
            this.recomloadingKeys = false;
            this.noDataTextKeys = 'No data to show';
          });
      },
      getKeysForAppPagination(cp, perPage) {
          this.loadingKeys = true;
          this.$apollo
          .query({
            query: SEARCH_BY_APP,
            variables: {
              appId : this.searchAppId,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              startDate: this.startDate,
              endDate: this.endDate,
              currPage: cp,
              itemsPerPage: perPage
            }
          })
          .then(data => {
            //console.log(data.data.searchByApp.keywords);
            if(data.data.searchByApp == null || data.data.searchByApp == undefined) {
              this.loadingKeys = false;
              this.noDataTextKeys = 'No data to show';
              return;
            }
            for (let index = 0; index < data.data.searchByApp.keywords.length; index++) {
              const element = data.data.searchByApp.keywords[index];
              this.keywordInsights.push(element);
            }
            this.filteredkeywordInsights = this.keywordInsights;
            this.loadingKeys = false;
          })
          .catch(error => {
            //console.log(error);
            this.loadingKeys = false;
            this.noDataTextKeys = 'No data to show';
          });
      },
      getDataForKeyword(e) {
          // const c = new VueSocketIO({
          //     debug: true,
          //     connection: 'http://localhost:8092'
          // })
          // console.log(c)



          if (e.keyCode == 13) {
            if(this.searchKey.length < 3){
              alert('Please enter atleast 3 characters');
              return;
          }

          if(this.socket) this.socket.disconnect()
          this.progress = false
          this.fromDb = true
          this.gotSoFar = 5
          this.clientId = Date.now()
          //console.log(this.clientId)
          this.outOf = 100
          this.progressValue = 0
          this.progressBufValue = 0
          this.progressErrCount = 0

          this.socket = io(API_WS_URL)

          this.socket.on("connect", () => {
            //console.log(this.socket.id);
          });

          this.socket.on("error", (error) => {
            if(this.socket) this.socket.disconnect()
            this.progress = false
            //console.log('Socket error');
          });

          this.socket.on('msgToClient', (message) => {
            //console.log(message)
            if(message.clientId != this.clientId || message.opId != 2 || message.keyword != this.searchKey) return
            //if(message.keyword != this.searchKey) return
            //this.fromDb = message.fromDB
            if(message.data == 'over'){
              this.progress = false
              this.socket.disconnect()
              return
            }
            this.gotSoFar = this.gotSoFar + 5
            this.progressValue = this.progressValue + (500/this.keywordDetails.appsRunningAds)
            this.progressBufValue = this.progressValue + (500/this.keywordDetails.appsRunningAds)
            if(message.err){
              this.progressErrCount = this.progressErrCount + 5
              return
            }
            const apps = JSON.parse(message.data)
            //console.log(apps)
            //this.progressText = 'Fetching more keywords... '+gotSoFar.toString()+'/'+this.appTPK.toString(),
            apps.map(x => {
              //console.log(x)
              //this.keywordInsights.push(x)
              this.applicationInsights.push(x)
            })
          })

          this.socket.on("disconnect", () => {
            this.progress = false
            //console.log('Client Disconnected');
          });

          //this.socket.emit('msgToServer', 'Hello')

          this.loadingApps = true;
          this.applicationInsights = [];
          this.keywordDetails.popularity = 0;
          this.keywordDetails.noOfApps = 0;
          this.keywordDetails.chance = 0;
          this.keywordDetails.appsRunningAds = 0;

          this.$apollo
          .query({
            query: SEARCH_BY_KEYWORD,
            variables: {
              keyword : this.searchKey,
              opId: 2,
              clientId: this.clientId,
              startDate : this.startDate,
              endDate : this.endDate,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              currPage: Date.now(),
              itemsPerPage: 1000
            }
          })
          .then(data => {
            //console.log(data.data);
            if(data.data.searchByKeyword == null || data.data.searchByKeyword == undefined) {
              this.loadingApps = false;
              this.noDataTextApps = 'No data to show';
              return;
            }
            updateCLF('kaiKeyword', this)
            this.applicationInsights = data.data.searchByKeyword.apps;
            this.keywordDetails.popularity = data.data.searchByKeyword.details.popularity;
            this.keywordDetails.noOfApps = data.data.searchByKeyword.details.noOfApps;
            this.keywordDetails.chance = data.data.searchByKeyword.details.chance;
            this.keywordDetails.appsRunningAds = data.data.searchByKeyword.details.appsRunningAds;
            this.loadingApps = false;
            this.outOf = this.keywordDetails.appsRunningAds
            this.progressValue = (500/this.keywordDetails.appsRunningAds)
            this.progressBufValue = this.progressValue + (500/this.keywordDetails.appsRunningAds)
            this.fromDb = data.data.searchByKeyword.fromDB;
            if(!this.fromDb) {
              this.progress = true
              const c = {
                done: false,
                preText : 'Fetching',
                text: ' Apps for keyword - '+this.searchKey,
                path: 'keyword-auction-insights',
                query: 'key',
                icon: 'mdi-apps',
                disabled: true,
                progress: (500/this.keywordDetails.appsRunningAds),
                total: this.keywordDetails.appsRunningAds,
                fetched: 5,
                clientId: this.clientId,
                appId : 'searching for a keyword',
                opId: 2,
                keyword: this.searchKey
              }
              this.$root.$emit('addDataRequests', c)
            }else{
              this.progress = false
              this.socket.disconnect()
            }
          })
          .catch(error => {
            //console.log(error);
            this.progress = false
            this.loadingApps = false;
          });
        }
      },
      getDataForKeywordPaginated(cp, perPage) {
        this.loadingApps = true;

        this.$apollo
        .query({
          query: SEARCH_BY_KEYWORD,
          variables: {
            keyword : this.searchKey,
            startDate : this.startDate,
            endDate : this.endDate,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            currPage: cp,
            itemsPerPage: perPage
          }
        })
        .then(data => {
          //console.log(data.data);
          if(data.data.searchByKeyword == null || data.data.searchByKeyword == undefined) {
            this.loadingApps = false;
            this.noDataTextApps = 'No data to show';
            return;
          }
          for (let index = 0; index < data.data.searchByKeyword.apps.length; index++) {
            const element = data.data.searchByKeyword.apps[index];
            this.applicationInsights.push(element);
          }
          this.loadingApps = false;
        })
        .catch(error => {
          //console.log(error);
          this.loadingApps = false;
        });
      },
      appClicked(appDet,appId){
        //console.log('App is clicked -'+appDet.title,appId);
        this.closeApp()
        this.searchAppId = parseInt(appId);
        this.searchApp = appDet.title;
        this.tab = 0;
        const fakeEvent = {
          keyCode : 13
        }
        this.getKeysForApp();
        //this.allAppKeywords();
      },
      keyClicked(key){
        //console.log('key is clicked -'+key);
        this.searchKey = key;
        this.tab = 1;
        const fakeEvent = {
          keyCode : 13
        }
        this.getDataForKeyword(fakeEvent);
      },
      plusKeyClicked(keyword){
        //console.log(keyword);
        //this.addedKeywords.push(keyword);
        this.showAddedKeyList = true;
        if (
          keyword &&
          !this.addedKeywords.find((kw) => kw.text === keyword) &&
          this.addedKeywords.length < KEYWORDS_PER_TRY
        ) {
          this.addedKeywords.push({
            text: keyword,
            matchType: 'EXACT',
            bidAmount: { currency: this.currency, amount: '0.01' },
          });
        }
      },
      deleteKeyword(keyword) {
        this.addedKeywords = this.addedKeywords.filter((kw) => kw.text !== keyword);
      },
      tabChanged(tab){
        // console.log('tabChanged', tab);
      },
      applyFilter(mode){
        if(mode == 1){
          //console.log('clear filters-');
          this.popularityRange[0] = 0;
          this.popularityRange[1] = 100;
          this.tags = [];
          this.filteredkeywordInsights = this.keywordInsights;
          return;
        }
        if(mode == 2){
          this.tags = [];
        }
        const popMin = this.popularityRange[0];
        const popMax = this.popularityRange[1];
        const keys = this.tags;
        this.filteredkeywordInsights = this.keywordInsights;
        let tempkeywordInsights = [];
        for (let index = 0; index < this.filteredkeywordInsights.length; index++) {
          const element = this.filteredkeywordInsights[index];
          if(keys.length!=0){
            keys.forEach(key => {
              if(parseInt(element.popularity) < popMax && parseInt(element.popularity) > popMin && element.name.includes(key)){
                tempkeywordInsights.push(element);
              }
            });
          }else{
            if(parseInt(element.popularity) < popMax && parseInt(element.popularity) > popMin){
                tempkeywordInsights.push(element);
            }
          }
        }
        this.filteredkeywordInsights = tempkeywordInsights;
      },
      closeApp(){
        //console.log('Close App is clicked -');
        this.searchAppId = '';
        this.searchApp = '';
        this.appSubTitle = '';
        this.appTitle = '';
        this.appIcon = '';
        this.appTPK = '-';
        this.appAC = '-';
        this.showAppNameSection = false;
        this.loadingKeys = false;
        this.noDataTextKeys = 'No data to show';
        this.allKeywords = [];
        this.fewKeywords = [];
        this.keywordInsights = [];
        this.filteredkeywordInsights = [];
        this.allAppKeyRanks = [];
      },
      recomcloseApp(){
        //console.log('Close App is clicked -');
        this.recomSearchAppId = '';
        this.searchRecoms = '';
        this.searchApp = '';
        this.recomappSubTitle = '';
        this.recomappTitle = '';
        this.recomappIcon = '';
        this.recomappTPK = '-';
        this.recomappAC = '-';
        this.recomshowAppNameSection = false;
        this.recomloadingKeys = false;
        this.noDataTextKeys = 'No data to show';
        //this.allKeywords = [];
        //this.fewKeywords = [];
        this.recomkeywordInsights = [];
        this.recomfilteredkeywordInsights = [];
        //this.allAppKeyRanks = [];
      },
      compCloseApp(){
        this.compappTitle = ''
        this.compappIcon = ''
        this.comps = []
        this.searchCompeteId = null
      },
      searchForApp(appName) {
        this.loading = true;
        this.$apollo
          .query({
            query: SEARCH_FOR_APPS,
            variables: {
              term : appName,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
            }
          })
          .then(res => {
            //console.log(res.data);
            this.searchedApps = res.data.searchForApps
            if(this.searchAppId !== this.prevSearchAppId){
              this.prevSearchAppId = this.searchAppId;
              if(this.searchAppId !== null && this.searchAppId !== '') {
                this.getKeysForApp();
              }
            }
            this.loading = false;
            this.disableAppSearch = false;
          })
          .catch(error => {
            //console.log(error);
            this.loading = false;
            this.disableAppSearch = false;
          });
      },
      async searchForCompetes(appId){
        // if(LSS.token && decodeToken(LSS.token).checkList){
        //   if(!(decodeToken(LSS.token).checkList.kaiComp)) await this.updateCL('kaiComp')
        // }
        this.compsLoading = true;
        this.comps = []
        this.compappTitle = '';
        this.compappIcon = '';
        this.$apollo
          .query({
            query: SEARCH_FOR_COMPETES,
            variables: {
              appId : this.searchCompeteId,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
            }
          })
          .then(res => {
            //console.log(res.data);
            updateCLF('kaiComp', this)
            const apps = res.data.getCompetitors
            this.comps = apps.map(x => {
              const t ={
                'title': x.title,
                'icon': x.icon,
                'id': x.id,
                'price': x.price,
                'rating': x.rating.toFixed(1),
                'genres': aptweak_categories[x.genres[0]],
                'version': x.version,
              }
              return t
            })
            this.compsLoading = false;
            this.$refs.compsSelectRef.focus()
          })
          .catch(error => {
            //console.log(error);
            this.compsLoading = false;
          });
      },
      searchForRecoms(appId){
        this.recomsLoading = true;
        this.recoms = []
        this.$apollo
          .query({
            query: KEYWORD_RECOMENDS,
            variables: {
              appid: this.recomSearchAppId,
              country: this.selectedCountries.Code.toLowerCase(),
              term: ""
            }
          })
          .then(res => {
            console.log(res.data);
            const keys = res.data.KeywordRecomends.content?res.data.KeywordRecomends.content:[];
            this.recoms = keys.map(x => {
              const t ={
                'keyword': x.keyword,
                'popularity': x.volume,
              }
              return t
            })
            this.recomsLoading = false;
          })
          .catch(error => {
            this.recomsLoading = false;
          });
      },
      getCategoryTop(){
        this.catTopLoading = true;
        this.catTop = []
        this.$apollo
          .query({
            query: GET_CATEGORY_TOP,
            variables: {
              catId : +(this.selCat),
              type : this.selCatType.toLowerCase(),
              now : 1,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
            }
          })
          .then(res => {
            //console.log(res.data);
            updateCLF('kaiTop', this)
            const apps = res.data.getCategoryTop
            this.catTop = apps.map(x => {
              const t ={
                'title': x.title,
                'icon': x.icon,
                'id': x.id,
                'price': x.price,
                'rating': x.rating.toFixed(1),
                'genres': aptweak_categories[x.genres[0]],
              }
              return t
            })
            this.catTopLoading = false;
          })
          .catch(error => {
            //console.log(error);
            this.catTopLoading = false;
          });
      },
      searchForAppCompetes(appName) {
        this.loadingCompetes = true;
        this.searchedCompetes =[]
        this.$apollo
          .query({
            query: SEARCH_FOR_APPS,
            variables: {
              term : appName,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
            }
          })
          .then(res => {
            //console.log(res.data);
            this.searchedCompetes = this.ownApps
            const apps = res.data.searchForApps
            const reduced = apps.map(x => {
              const item = {
                'title': x.title,
                'id': x.id
              }
              return item
            })
            //console.log(reduced)
            //this.searchedCompetes = reduced
            reduced.map(y => this.searchedCompetes.push(y))
            // if(this.searchCompeteId !== this.prevSearchCompeteId){
            //   this.prevSearchCompeteId = this.searchCompeteId;
            //   if(this.searchCompeteId !== null && this.searchCompeteId !== '') {
            //     //console.log(this.searchCompeteId)
            //     this.searchForCompetes(this.searchCompeteId);
            //   }
            // }
            this.loadingCompetes = false;
            //this.disableCompeteSearch = false;
          })
          .catch(error => {
            //console.log(error);
            this.loadingCompetes = false;
            //this.disableCompeteSearch = false;
          });
      },
      searchForAppRecoms(appName) {
        this.recomSearchLoading = true;
        this.searchedRecoms = []
        this.$apollo
          .query({
            query: SEARCH_FOR_APPS,
            variables: {
              term : appName,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
            }
          })
          .then(res => {
            this.searchedRecoms = this.ownApps
            const apps = res.data.searchForApps
            const reduced = apps.map(x => {
              const item = {
                'title': x.title,
                'id': x.id
              }
              return item
            })
            reduced.map(y => this.searchedRecoms.push(y))
            this.recomSearchLoading = false;
          })
          .catch(error => {
            this.recomSearchLoading = false;
          });
      },
      getOwnApps(){
        this.$apollo
        .query({
          query: OWN_APPS,
          variables: {
            from: 'competitors',
          },
        })
        .then((res) => {
          //console.log(res.data);
          // const apps  = res.data.getAllOwnApps;
          const apps = res.data.getAllOwnApps?.filter((v,i,a)=>a.findIndex(t=>(t.adamId === v.adamId))===i)
          //console.log(this.ownApps)
          this.ownApps = []
          this.ownApps.push({
            'header': 'Your Apps',
            'divider': true
          })
          apps.map(x => {
            const item = {
              'title': x.appName,
              'id': x.adamId
            }
            this.ownApps.push(item)
          })
          this.ownApps.push({
            'header': 'Suggested Apps',
            'divider': true
          })
          this.searchedCompetes = this.ownApps
          this.searchedRecoms = this.ownApps
        })
        .catch((error) => {
          //console.log(error);
        });
      },
      pageChange(page) {
        //console.log('pageChange ',page);
      },
      runAds(){
        //console.log(this.addedKeywords);
        this.showAddedKeyList= false;
        LSS.keysFromKai = this.addedKeywords;
        this.selectAdgroupForKeywords = {
          from : 'kai',
          now: Date.now(),
          keysFromKai : this.addedKeywords
        }
      },
      adgroupsSelectedForKeyword(appsWithCampaigns) {
        //console.log('adgroupsSelectedForKeyword',appsWithCampaigns);
        const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
        //this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
        this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
      },
      adgroupsKeysFromKai(data) {
        //console.log('adgroupsKeysFromKai',data);
        const adgroups = this.getNested(data.camps, 'ADGROUP');
        this.adgroupMetadata = { selectedAdgroups: adgroups, KeysFromKai: data.keys, adamId: this.searchAppId, now: Date.now() };
      },
      openSelectAdgroupForKeywords(event) {
        //console.log('openSelectAdgroupForKeywords',event);
        this.selectAdgroupForKeywords = {
          from : 'kai',
          now: Date.now(),
          keysFromKai : event
        }
      },
      async addkeywordsToAdgroups(keywords) {
        //console.log('added in kai - keywords',keywords);
        const addKeywordsReq = {
        keywords: keywords,
          adgroups: this.adgroupMetadata.selectedAdgroups,
        };

        try{
          const result = await this.$apollo.mutate({
          mutation: ADD_KEYWORDS,
          variables: {
            addKeywords: addKeywordsReq,
          },
        });
        if (result.data.addKeywords.searchAdsErrors) {
          this.$notify.error(result.data.addKeywords.searchAdsErrors);
        } else {
          this.$notify.success('Keywords Added.');
          this.closeButton();
        }
        }
        catch{}
      },
      getNested(appsWithCampaigns, level) {
        // returning nested data as requested (apps > campaigns > adgroups)
        return level === 'APP'
          ? appsWithCampaigns
          : [].concat(
              ...appsWithCampaigns.map((app) =>
                level === 'CAMPAIGN'
                  ? app.campaigns
                  : [].concat(
                      ...app.campaigns.map((campaign) => campaign.adgroups)
                    )
              )
            );
      },
      countryChanged(item){
        this.langs = this.selectedCountries.Languages
        this.selectedLang = this.selectedCountries.Languages[0]
        this.language = this.selectedCountries.Languages[0].Code
      },
      langChanged(){
        this.language = this.selectedLang
      },
      dateChanged(){
        const d = this.endDate+'T00:00:00.000Z';
        const dt = new Date(d);
        this.startDate = new Date(dt.setDate(dt.getDate()-60)).toISOString().substr(0, 10);
        this.menu5 = false;
      },
      debounce: function(query) {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.searchForApp(query);
        }, 500);
      },
      debounce2: function(query) {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.searchForAppCompetes(query);
        }, 500);
      },
      debounce3: function(query) {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.searchForAppRecoms(query);
        }, 500);
      },
      allAppKeywords(){
        this.$apollo
          .query({
            query: APP_KEYWORDS_FULL,
            variables: {
              appIds : this.searchAppId,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              startDate: this.startDate,
              endDate: this.endDate,
            }
          })
          .then(data => {
            console.log(data.data);
            this.allKeywords = data.data.getAppKeywordsFULL[0].keywords
          })
          .catch(error => {
            console.log(error);
          });
      },
      openQuickAddWithSource(source) {
        this.sourceKeywords = source;
        this.quickAddDialog = true;
      },
      addAllKeyClicked(){
        this.addedKeywords = [];
        this.quickAddDialog = false;
        this.showAddedKeyList = true;

        const kwsOfInterest = this.sourceKeywords.filter(keywordItem => this.checkQuickAddCriteria(keywordItem)).slice(0, KEYWORDS_PER_TRY);

        for(const keyword of kwsOfInterest){
          if (
            keyword &&
            !this.addedKeywords.find((kw) => kw.text === keyword.name) &&
            this.addedKeywords.length < KEYWORDS_PER_TRY
            ) {
            this.addedKeywords.push({
              text: keyword.name,
              matchType: 'EXACT',
              bidAmount: { currency: this.currency, amount: '0.01' },
            });
          }
        }
      },
      checkQuickAddCriteria(keywordItem) {
        for(const criteriaKey in this.quickAddModel) {
          let result;
          const compareValue = Number(keywordItem[criteriaKey]);
          const criteriaValues = this.quickAddModel[criteriaKey];

          if((criteriaValues.criteria === CriteriaOptions.IN_RANGE ? _.isNil(criteriaValues.from) || _.isNil(criteriaValues.to) : _.isNil(criteriaValues.value))) continue;

          switch(criteriaValues.criteria) {
            case CriteriaOptions.GREATER_THAN: {
              result =  compareValue > Number(criteriaValues.value);
              break;
            }
            case CriteriaOptions.LESS_THAN: {
              result = compareValue < Number(criteriaValues.value);
              break;
            }
            case CriteriaOptions.EQUALS: {
              result = compareValue === Number(criteriaValues.value);
              break;
            }
            case CriteriaOptions.IN_RANGE: {
              result = compareValue >= Number(criteriaValues.from) && compareValue <= Number(criteriaValues.to);
              break;
            }
          }

          if(!result) return false;
        }

        return true;
      },
      closeButton(){
        this.showAddedKeyList = false,
        this.addedKeywords = []
      },
    },
    beforeRouteUpdate(to, from, next){
      //console.log(to)
      //console.log(LSS.kaiQuery)
      if(LSS.kaiQuery && LSS.kaiQuery.tab) this.tab = parseInt(LSS.kaiQuery.tab);
      if(LSS.kaiQuery && LSS.kaiQuery.keyword){
        this.tab = 1;
        this.searchKey = LSS.kaiQuery.keyword
        const fakeEvent = {
          keyCode : 13
        }
        this.getDataForKeyword(fakeEvent);
      }
      if(LSS.kaiQuery && LSS.kaiQuery.appId){
        if(parseInt(LSS.kaiQuery.tab) == 0){
          this.tab = 0;
          this.closeApp()
          this.searchAppId = parseInt(LSS.kaiQuery.appId);
          this.getKeysForApp();
        }
        if(parseInt(LSS.kaiQuery.tab) == 4){
          this.tab = 4;
          this.recomcloseApp()
          this.recomSearchAppId = parseInt(LSS.kaiQuery.appId);
          this.getRecomKeysForApp();
        }
      }
      LSS.kaiQuery = null

      const tab = this.$route.query.tab ? this.$route.query.tab : '';
      if (tab == 'comp') {
        this.tab = 2;
      } else if (tab == 'recom') {
        this.tab = 4;
      } else if (tab == 'key') {
        this.tab = 1;
      } else if (tab == 'app') {
        this.tab = 0;
      } else if (tab == 'top') {
        this.tab = 3;
      }

    },
    watch: {
      quickAddDialog(value) {
        // resetting the dialog
        if (value) {
          for (const criteriaKey in this.quickAddModel) {
            for (const filterKey in this.quickAddModel[criteriaKey]) {
              this.quickAddModel[criteriaKey][filterKey] = null;
            }
          }

          this.isOrganicRange = undefined;
          this.isPopularityRange = undefined;
          this.isAppsRange = undefined;
        }
      },
      tagSearch(data) {
        //console.log(data);
      },
      search(query) {
        this.disableAppSearch = true;
        query &&
        query !== this.searchAppId &&
        query.length > 2 &&
        this.debounce(query);
      },
      searchCompete(query) {
        query &&
        query !== this.searchCompeteId &&
        query.length > 2 &&
        this.debounce2(query);
      },
      searchRecom(query) {
        query &&
        query !== this.searchCompeteId &&
        query.length > 2 &&
        this.debounce3(query);
      },
      options(val) {
        // if(this.page != this.pageCount || this.pagecount == 0){
        //   return;
        // }
        // this.loadingKeys = true;
        // this.currPage = val.page;

        // //to calculate length of last set
        // const totalSets = Math.ceil(this.appTPK/10);
        // let idx = 10;
        // if(this.currPage == totalSets){
        //     idx = (this.appTPK%10)-1;
        // }
        // this.getKeysForAppPagination(this.currPage, idx);
        //this.allAppKeywords()

      },
      options2(val) {
        // console.log('options2 ',val);
        // console.log("keyword Page now",this.page2)
        // console.log("keyword Page total",this.pageCount2)


        // if(this.page2 != this.pageCount2 || this.pagecount2 == 0){
        //   return;
        // }
        // this.loadingApps = true;
        // this.currPage2 = val.page;

        // //to calculate length of last set
        // const totalSets = Math.ceil(this.keywordDetails.appsRunningAds/10);
        // let idx = 10;
        // if(this.currPage2 == totalSets){
        //     idx = (this.keywordDetails.appsRunningAds%10)-1;
        // }
        // this.getDataForKeywordPaginated(this.currPage2, idx);
      }
    },
    beforeMount(){
      this.Countries = ATCODES;
      this.selectedCountries = ATCODES[88]
      this.langs = this.selectedCountries.Languages
      this.selectedLang = this.selectedCountries.Languages[0]
      this.language = 'en';
      this.device = 'iphone';
      const d = this.endDate+'T00:00:00.000Z';
      const dt = new Date(d);
      this.startDate = new Date(dt.setDate(dt.getDate()-60)).toISOString().substr(0, 10);
    },
    mounted(){
      //console.log('kai mounted')
      //console.log(LSS.kaiQuery)
      if(LSS.kaiQuery && LSS.kaiQuery.tab) this.tab = parseInt(LSS.kaiQuery.tab);
      if(LSS.kaiQuery && LSS.kaiQuery.keyword){
        this.searchKey = LSS.kaiQuery.keyword
        const fakeEvent = {
          keyCode : 13
        }
        this.getDataForKeyword(fakeEvent);
      }
      if(LSS.kaiQuery && LSS.kaiQuery.appId){
        if(parseInt(LSS.kaiQuery.tab) == 0){
          this.closeApp()
          this.searchAppId = parseInt(LSS.kaiQuery.appId);
          this.getKeysForApp();
        }
        if(parseInt(LSS.kaiQuery.tab) == 4){
          this.recomcloseApp()
          this.recomSearchAppId = parseInt(LSS.kaiQuery.appId);
          this.getRecomKeysForApp();
        }
      }
      LSS.kaiQuery = null

      const tab = this.$route.query.tab ? this.$route.query.tab : '';
      if (tab == 'comp') {
        this.tab = 2;
      } else if (tab == 'recom') {
        this.tab = 4;
      } else if (tab == 'key') {
        this.tab = 1;
      } else if (tab == 'app') {
        this.tab = 0;
      } else if (tab == 'top') {
        this.tab = 3;
      }

      this.getOwnApps()
      this.getCategoryTop()
      this.categories = at_cats.filter(x => x.id!=0)
    }
  })
