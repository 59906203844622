







































































































































































































































































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import { OWN_APPS, KEYWORD_RECOMENDS, APP_METADATA } from '../../utils/apptweakqueries';
import { LSS } from '@core/services';
import { MaxCPTBidOptions } from '../../utils/enums';

export default Vue.extend({
  name: 'AddKeysKai',
  data() {
    return {
      valid: false,
      msgModel: false,
      message :'',
      msgTitle: '',
      amountRules: [
        v => !!v || 'Bid amount is reuired',
        v => (v && v != 0 && v>0) || 'Bid amount must be more than 0',
      ],
      searchApp : {
        adamId : 0,
        appName : ''
      },
      loadingApps : false,
      searchedApps : [],
      step : 1,
      first:'',
      searchedKeyword: '',
      recomendHeaders: [
          {
            text: 'Keyword',
            align: 'start',
            value: 'keyword',
          },
          { text: 'Popularity', value: 'volume' },
          {
            text: '',
            value: 'addKeywordPlus',
          },
      ],
      recomendItems: [],
      loadingRecomends:false,
      selectedCountry: {
        name: 'United States',
        code: 'us'
      },
      countries: [
        {
          name: 'United States',
          code: 'us'
        },
        {
          name: 'India',
          code: 'in'
        }
      ],
      selectedAppName:'Select App',
      selectedAppIcon: '',
      selectedAppDev: '',

      matchTypes: ['EXACT', 'BROAD'],
      maxCptBidOptions: [
        { text: 'Ad Group Default', value: MaxCPTBidOptions.ADGROUP_DEFAULT },
        { text: 'Custom', value: MaxCPTBidOptions.CUSTOM },
      ],
      maxCptBid: MaxCPTBidOptions.CUSTOM,

      show: false,
      keywordInput: '',
      keywords: [],
      keysFromKai: [],
      currency: '',

      selectedAdgroups: [],
      totalCampaigns: 0,
      totalAdgroups: 0,
      fullscreenLoader: null,
    };
  },
  props: {
    adgroupDetails: {
      type: Object,
      required: false,
    },
  },
  watch: {
    adgroupDetails: function (data) {
      //console.log(data);
      this.show = true;
      if(data.adamId) {
        this.changeApp(data.adamId);
      }
      if(data.KeysFromKai?.length > 0){
        this.keywords = data.KeysFromKai;
      }
      this.selectedAdgroups = data?.selectedAdgroups;
      // using same currency as in adgroup settings
      this.currency = this.selectedAdgroups[0]?.defaultBidAmount.currency;

      this.totalAdgroups = this.selectedAdgroups.length;
      this.totalCampaigns = _.uniq(
        this.selectedAdgroups.map((group) => group.campaignId)
      ).length;
    },
  },
  methods: {
    close() {
      this.show = false;
      this.keywordInput = '';
      this.keywords = [];
    },
    getAppDetails(){
      //console.log('getAppDetails');
    },
    addKeyword(): void {
      const keys = this.keywordInput.split(',');
      //console.log(this.keywords);
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (
          element &&
          !this.keywords.find((kw) => kw.text === element)
        ) {
          //console.log('in ',this.keywords);
          this.keywords.push({
            text: element,
            matchType: 'EXACT',
            bidAmount: { currency: this.currency, amount: '0.1' },
          });
        }
        this.keywordInput = '';
      }
    },

    deleteKeyword(keyword: string): void {
      this.keywords = this.keywords.filter((kw) => kw.text !== keyword);
    },

    save() {
      this.$refs.form.validate();
      for (let index = 0; index < this.keywords.length; index++) {
        const element = this.keywords[index];
        if(element.bidAmount.amount == 0 || element.bidAmount.amount == '' || element.bidAmount.amount < 0){
          this.msgModel = true;
          this.msgTitle = 'Info!!!'
          this.message = "Bid amount must be greater 0"
          break;
        }
      }
      this.$emit('onSaveKeywords', this.keywords);
      this.close();
    },

    change() {
      this.$emit('onClickChange', this.keysFromKai);
      this.close();
    },
    changeApp(adamId){
      //console.log('changeApp',adamId, this.selectedCountry.code);
      this.fullscreenLoader = this.$loading.show();
      this.loadingRecomends = true;
      this.$apollo
      .query({
        query: KEYWORD_RECOMENDS,
        variables: {
          appid: adamId,
          country: this.selectedCountry.code,
          term: ""
        }
      })
      .then(res => {
        //console.log(res.data);
        this.loadingRecomends = false;
        this.recomendItems = res.data.KeywordRecomends.content?res.data.KeywordRecomends.content:[];
        this.fullscreenLoader.hide();
      })
      .catch(error => {
        //console.log(error);
        this.loadingRecomends = false;
        this.fullscreenLoader.hide();
      });

      this.$apollo
      .query({
        query: APP_METADATA,
        variables: {
          appId : adamId,
          location: this.selectedCountry.code,
          language: 'en',
          device: 'iphone',
          startDate: new Date().toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10),
        }
      })
      .then(data => {
        //console.log(data.data);
        if(data.data.getAppMetadata == null || data.data.getAppMetadata == undefined) {
          this.loadingKeys = false;
          this.noDataTextKeys = 'App details not found';
          return;
        }
        this.selectedAppName = data.data.getAppMetadata.content.title;
        this.selectedAppIcon = data.data.getAppMetadata.content.icon;
        this.selectedAppDev = data.data.getAppMetadata.content.developer.name;
      })
      .catch(error => {
        //console.log(error);
        this.loadingKeys = false;
        this.noDataTextKeys = 'App details not found';
      });
    },
    addKeywordPlusClicked(item){
      //console.log('addKeywordPlusClicked',item);
      if (
        item.keyword &&
        !this.keywords.find((kw) => kw.text === item.keyword)
      ) {
        this.keywords.push({
          text: item.keyword,
          matchType: 'EXACT',
          bidAmount: { currency: this.currency, amount: '0.1' },
        });
      }
    },
    countryChange(item){
      //console.log('countryChange',item);
      this.selectedCountry = item;
    }
  },
  mounted(){
    //console.log('mountedAddKeywords');
  },
  beforeUpdate(){
    //console.log(LSS.keysFromKai);
    if(LSS.keysFromKai?.length > 0){
      //console.log('keysFromKai');
      this.keywords = LSS.keysFromKai;
      this.keysFromKai = LSS.keysFromKai;
      LSS.keysFromKai = [];
    }
    //console.log(this.keywords);
    //console.log(this.keysFromKai);
  },
});
